import React, { useState, useEffect } from 'react';
import GpCamera from '../components/GpCamera';
import SendGoodPhoto from '../components/SendGoodPhoto';
import AnonButton from '../components/AnonButton'; // Import the new AnonButton component
import { v4 as uuidv4 } from 'uuid';
import './GoodPage.css';

const collectUserInfo = (userId) => {
  const userInfo = {
    id: userId,
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    colorDepth: window.screen.colorDepth,
    deviceMemory: navigator.deviceMemory || null,
    maxTouchPoints: navigator.maxTouchPoints || null,
    connectionType: navigator.connection ? navigator.connection.effectiveType : null,
    downlink: navigator.connection ? navigator.connection.downlink : null,
    battery: null,
    geolocation: null,
  };

  return userInfo;
};

const GoodPage = () => {
  const [imageSrc, setImageSrc] = useState("");
  const [gpsLocation, setGpsLocation] = useState(null);
  const [userData, setUserData] = useState(null);
  const [anonMode, setAnonMode] = useState(false); // State to manage anonymous mode

  useEffect(() => {
    const userId = uuidv4();  // Generate unique user ID
    const userInfo = collectUserInfo(userId); // Collect user info

    // Fetch the user's geolocation
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setGpsLocation(location);
          userInfo.geolocation = location;  // Add geolocation to user info
          setUserData(userInfo);  // Store user data in state
          console.log('User data with GPS:', userInfo); // Check userData
        },
        (error) => console.error("Error getting location:", error)
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleAnonModeChange = (newAnonMode) => {
    setAnonMode(newAnonMode);
    console.log('Anon Mode:', newAnonMode); // Log the new anonMode value
  };

  return (
    <div className="good-page">
      <h1>the Good Side</h1>
      <GpCamera onCapture={setImageSrc} />
      
      {/* Anonymity button */}
      <AnonButton onSelectAnon={handleAnonModeChange} />

      {/* Pass userData, imageSrc, and anonMode to the SendGoodPhoto component */}
      <SendGoodPhoto 
        imageSrc={imageSrc} 
        gpsLocation={gpsLocation} 
        userData={{ ...userData, hasSelectedAnon: anonMode }} // Include anonMode in userData
        anonMode={anonMode} 
      />
    </div>
  );
};

export default GoodPage;
