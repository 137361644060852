import React from 'react';
import MapHeatGenerator from '../components/MapHeatGenerator.jsx';
import './MapPage.css';

const MapPage = () => {
  return (
    <div className="map-page">
      <h1>Heatmap Example</h1>
      <MapHeatGenerator />
    </div>
  );
};

export default MapPage;
