// router/AppRouter.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';  // Import the new HomePage component
import GoodPage from '../pages/GoodPage';
import EvilPage from '../pages/EvilPage';
import MapPage from '../pages/MapPage';
import GoodMapPage from '../pages/GoodMapPage';
import EvilMapPage from '../pages/EvilMapPage';

const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<HomePage />} />  {/* Set HomePage as the default landing page */}
      <Route path="/good" element={<GoodPage />} />
      <Route path="/evil" element={<EvilPage />} />
      <Route path="/map" element={<MapPage />} />
      <Route path="/goodmap" element={<GoodMapPage />} />
      <Route path="/evilmap" element={<EvilMapPage />} />
    </Routes>
  );
};

export default AppRouter;
