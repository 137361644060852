import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import PhotoCameraRoundedIcon from '@mui/icons-material/PhotoCameraRounded';

const EpCamera = ({ onCapture }) => {
  const [source, setSource] = useState("");

  const handleCapture = (target) => {
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const newUrl = URL.createObjectURL(file);
      setSource(newUrl);
      onCapture(newUrl);  // Pass the captured image data to the parent
    }
  };

  return (
    <div className="ep-camera">
      <h3>Capture your image</h3>
      {source && (
        <div>
          <img src={source} alt="Captured" style={{ maxWidth: '100%' }} />
        </div>
      )}
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="icon-button-file"
        type="file"
        capture="environment"
        onChange={(e) => handleCapture(e.target)}
      />
      <label htmlFor="icon-button-file">
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCameraRoundedIcon fontSize="large" />
        </IconButton>
      </label>
    </div>
  );
};

export default EpCamera;
