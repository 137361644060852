import React, { useState } from 'react';

const AnonButton = ({ onSelectAnon }) => {
    const [isAnon, setIsAnon] = useState(false);

    // Toggle anonymous state and notify parent component
    const handleToggle = () => {
        setIsAnon((prevAnon) => {
            const newAnon = !prevAnon;
            onSelectAnon(newAnon);
            console.log(`Anon Mode: ${newAnon}`); // Log the new state value
            return newAnon;
        });
    };

    return (
        <div className="anon-button">
            <button onClick={handleToggle} className={isAnon ? 'anon-active' : 'anon-inactive'}>
                {isAnon ? 'Anon ON' : 'Anon OFF'}
            </button>
        </div>
    );
};

export default AnonButton;
