import React from 'react';
import './EvilMapPage.css'; // Assuming you'll style this separately
import EvilMapHeatGenerator from '../components/EvilMapHeatGenerator'; // Will add this later

const EvilMapPage = () => {
  return (
    <div className="evil-map-page">
      <h1>Evil Map Heatmap</h1>
      <EvilMapHeatGenerator />
    </div>
  );
};

export default EvilMapPage;
