import React from 'react';
import './GoodMapPage.css'; // Assuming you'll style this separately
import GoodMapHeatGenerator from '../components/GoodMapHeatGenerator'; // Will add this later

const GoodMapPage = () => {
  return (
    <div className="good-map-page">
      <h1>Good Map Heatmap</h1>
      <GoodMapHeatGenerator />
    </div>
  );
};

export default GoodMapPage;
