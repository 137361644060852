import React, { useState } from 'react';
import { Button } from '@mui/material';

const SendEvilPhoto = ({ imageSrc, gpsLocation, userData, anonMode }) => {
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const handleSend = async () => {
        if (!imageSrc) {
            setConfirmationMessage('No image to send.');
            console.error('No image to send');
            return;
        }

        try {
            // Fetch image blob
            console.log('Fetching image blob from:', imageSrc);
            const response = await fetch(imageSrc);

            if (!response.ok) {
                console.error('Failed to fetch image blob:', response.statusText);
                setConfirmationMessage('Failed to fetch image.');
                return;
            }

            const blob = await response.blob();
            console.log('Image blob fetched successfully.');

            // Create FormData and append image, userData, and other details
            const formData = new FormData();
            formData.append('file', blob, 'evil_photo.png');  // Update file name to 'evil_photo.png'
            formData.append('userData', JSON.stringify(userData));
            formData.append('hasSelectedAnon', anonMode);  // Include anonMode in the request
            console.log('Anon Mode being sent:', anonMode);

            console.log('Appending GPS coordinates to form data:', gpsLocation);
            // Append GPS coordinates if available
            if (gpsLocation) {
                formData.append('latitude', gpsLocation.lat || 'undefined');
                formData.append('longitude', gpsLocation.lng || 'undefined');
            } else {
                formData.append('latitude', 'undefined');
                formData.append('longitude', 'undefined');
            }

            console.log('Sending POST request to upload photo.');
            // Send POST request
            const uploadResponse = await fetch('https://evil.wearecryptoradio.com/upload/evilphoto', {
                method: 'POST',
                body: formData,
            });

            if (uploadResponse.ok) {
                setConfirmationMessage('Your evil photo and data have been uploaded successfully!');
                console.log('Evil photo and user data uploaded successfully!');
            } else {
                setConfirmationMessage(`Failed to upload evil photo: ${uploadResponse.statusText}`);
                console.error('Failed to upload evil photo:', uploadResponse.statusText);
            }
        } catch (error) {
            setConfirmationMessage('Error uploading evil photo.');
            console.error('Error uploading evil photo:', error);
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleSend}>
                Send Evil Photo
            </Button>
            {confirmationMessage && <p>{confirmationMessage}</p>}
        </div>
    );
};

export default SendEvilPhoto;
