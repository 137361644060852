import React, { useState, useEffect } from 'react';
import EpCamera from '../components/EpCamera';
import SendEvilPhoto from '../components/SendEvilPhoto';
import { v4 as uuidv4 } from 'uuid';
import './EvilPage.css';
import AnonButton from '../components/AnonButton'; // Import the AnonButton component

const collectUserInfo = (userId) => {
    const userInfo = {
        id: userId,
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        colorDepth: window.screen.colorDepth,
        deviceMemory: navigator.deviceMemory || null,
        maxTouchPoints: navigator.maxTouchPoints || null,
        connectionType: navigator.connection ? navigator.connection.effectiveType : null,
        downlink: navigator.connection ? navigator.connection.downlink : null,
        battery: null,
        geolocation: null,
    };

    return userInfo;
};

const EvilPage = () => {
    const [imageSrc, setImageSrc] = useState("");
    const [gpsLocation, setGpsLocation] = useState(null);
    const [userData, setUserData] = useState(null);
    const [anonMode, setAnonMode] = useState(false); // State to manage anonymous mode

    useEffect(() => {
        const userId = uuidv4();
        const userInfo = collectUserInfo(userId);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const location = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setGpsLocation(location);
                    userInfo.geolocation = location;
                    setUserData(userInfo);
                    console.log('User data with GPS:', userInfo); // Check userData
                },
                (error) => console.error("Error getting location:", error)
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    const handleAnonModeChange = (newAnonMode) => {
        setAnonMode(newAnonMode);
        console.log('Anon Mode:', newAnonMode); // Log the new anonMode value
    };

    return (
        <div className="evil-page">
            <h1>the Evil Side</h1>
            <EpCamera onCapture={setImageSrc} />

            {/* Anonymity button */}
            <AnonButton onSelectAnon={handleAnonModeChange} />

            {/* Pass userData, imageSrc, and anonMode to the SendEvilPhoto component */}
            <SendEvilPhoto 
                imageSrc={imageSrc} 
                gpsLocation={gpsLocation} 
                userData={{ ...userData, hasSelectedAnon: anonMode }} // Include anonMode in userData
                anonMode={anonMode} 
            />
        </div>
    );
};

export default EvilPage;
