import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <div className="home-page">
      <div className="neon-background">
        <h1 className="neon-text">Explore the Universe</h1>
        <div className="card-container">
          <div className="neon-card" onClick={() => handleNavigate('/good')}>
            <div className="card-content">
              <h2>Good</h2>
              <p>Discover the good side</p>
            </div>
          </div>
          <div className="neon-card" onClick={() => handleNavigate('/evil')}>
            <div className="card-content">
              <h2>Evil</h2>
              <p>Explore the dark side</p>
            </div>
          </div>
          <div className="neon-card" onClick={() => handleNavigate('/map')}>
            <div className="card-content">
              <h2>Map</h2>
              <p>Check out the map</p>
            </div>
          </div>
          <div className="neon-card" onClick={() => handleNavigate('/goodmap')}>
            <div className="card-content">
              <h2>Good Map</h2>
              <p>See the good map</p>
            </div>
          </div>
          <div className="neon-card" onClick={() => handleNavigate('/evilmap')}>
            <div className="card-content">
              <h2>Evil Map</h2>
              <p>Explore the evil map</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
