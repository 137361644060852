import React, { useState, useEffect, useRef, useCallback } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const libraries = ['visualization'];

const EvilMapHeatGenerator = () => {
  const mapRef = useRef(null);
  const [heatmap, setHeatmap] = useState(null);

  // Validate that lat and lng are numbers
  const isValidLatLng = (lat, lng) => {
    return !isNaN(lat) && !isNaN(lng);
  };

  // Function to load points from a given file
  const getPointsFromFile = async (fileName) => {
    try {
      if (!window.google || !window.google.maps) {
        throw new Error("Google Maps API is not loaded yet.");
      }

      const response = await fetch(`/${fileName}`);
      const text = await response.text();
      const lines = text.split('\n');
      const points = lines
        .map(line => {
          const [, lat, lng] = line.split(',');
          const latFloat = parseFloat(lat);
          const lngFloat = parseFloat(lng);
          if (isValidLatLng(latFloat, lngFloat)) {
            return new window.google.maps.LatLng(latFloat, lngFloat);
          } else {
            console.warn(`Invalid coordinates: ${lat}, ${lng}`);
            return null;
          }
        })
        .filter(point => point !== null); // Filter out invalid points

      return points;
    } catch (error) {
      console.error('Error loading points from file:', error);
      return [];
    }
  };

  const initHeatmap = useCallback(async (fileName) => {
    const points = await getPointsFromFile(fileName);
    if (mapRef.current && points.length > 0) {
      const heatmapLayer = new window.google.maps.visualization.HeatmapLayer({
        data: points,
        map: mapRef.current,
      });
      setHeatmap(heatmapLayer);
    }
  }, []);

  const toggleHeatmap = () => {
    if (heatmap) {
      heatmap.setMap(heatmap.getMap() ? null : mapRef.current);
    }
  };

  const changeGradient = () => {
    const gradient = [
      "rgba(255, 0, 0, 0)",
      "rgba(255, 0, 255, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 255, 255, 1)",
      "rgba(0, 255, 0, 1)",
      "rgba(255, 255, 0, 1)",
      "rgba(255, 0, 0, 1)"
    ];
    if (heatmap) {
      heatmap.set("gradient", heatmap.get("gradient") ? null : gradient);
    }
  };

  const changeRadius = () => {
    if (heatmap) {
      heatmap.set("radius", heatmap.get("radius") ? null : 20);
    }
  };

  const changeOpacity = () => {
    if (heatmap) {
      heatmap.set("opacity", heatmap.get("opacity") ? null : 0.2);
    }
  };

  useEffect(() => {
    if (window.google && window.google.maps) {
      initHeatmap('evilGeoCodes.txt'); // Default load
    }
  }, [initHeatmap]);

  return (
    <div>
      <LoadScript
        googleMapsApiKey={apiKey}
        libraries={libraries}
        loadingElement={<div>Loading...</div>}
        onLoad={() => initHeatmap('evilGeoCodes.txt')} // Initialize with good data on load
      >
        <GoogleMap
          id="heatmap-example"
          mapContainerStyle={{ width: '90vw', height: '80vh' }}
          center={{ lat: 39.8283, lng: -98.5795 }}
          zoom={5}
          mapTypeId="satellite"
          onLoad={map => (mapRef.current = map)}
        />
      </LoadScript>

      <div>
        <button onClick={toggleHeatmap}>Toggle Heatmap</button>
        <button onClick={changeGradient}>Change Gradient</button>
        <button onClick={changeRadius}>Change Radius</button>
        <button onClick={changeOpacity}>Change Opacity</button>
      </div>
    </div>
  );
};

export default EvilMapHeatGenerator;
